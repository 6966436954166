html, body, #app {
    font-family: 'courier new', monospace;
    margin: 0;
    padding: 0;
    background-color: black;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

